import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"

export default ({ data }) => {
  const { title, bodyBgColor, bodyTextColor, bodyTitleColor, seo } = data.contentfulStaticPage
  const offices = data.allContentfulOffice
  const employees = data.allContentfulEmployee
  const contactDetails = data.allContentfulContactDetail.edges

  return (
    <Layout
      bodyBgColor={bodyBgColor}
      bodyTextColor={bodyTextColor}
      bodyTitleColor={bodyTitleColor}
      pageTitle={seo && seo.seoTitle ? seo.seoTitle : title}
      pageDescription={seo && seo.description ? seo.description : null}
      pageImage={seo && seo.image ? seo.image.file.url : null}
      pageArticle={seo && seo.article ? seo.article : false}
      pathname={'/contact'}
    >
      <div className="container mt-32 md:mt-42">

        <h1>{title}</h1>

        {
          contactDetails &&
          <div className="mb-20 md:mb-26 flex flex-wrap flex-row -mx-6">
          {contactDetails.map((contact, index) => (
            <div key={index} className={`w-full md:w-1/2 px-6 lg:flex-auto lg:w-auto mt-8 md:mt-12`}>
              <h4 className="text-white">{contact.node.title}</h4>
              {contact.node.textLine && <div>{contact.node.textLine}</div>}
              {
                contact.node.emailLine &&
                <div className="flex flex-col items-start">
                {contact.node.emailLine.map((email, index) => (
                  <a key={index} className="truncate max-w-full" href={`mailto:${email}`}>{email}</a>
                ))}
                </div>
              }
            </div>
          ))}
          </div>
        }

        {
          offices &&
          <div className="mb-20 md:mb-26">
            <h2>Offices</h2>

            <div className="flex flex-wrap -mx-8 mt-6 md:mt-8">
              {offices.edges.map((office, index) => (
                <div key={index} className={`w-full md:w-1/3 px-8 ${index > 0 ? 'mt-12 md:mt-0' : ''}`}>
                  <h3 className="text-white">{office.node.title}</h3>
                  <div dangerouslySetInnerHTML={{ __html: office.node.addressLine.childMarkdownRemark.html }}></div>
                  {office.node.googleMapsUrl && <a href={office.node.googleMapsUrl}>Show on Google Maps</a>}
                </div>
              ))}
            </div>
          </div>
        }

        {
          employees &&
          <div className="mb-8 md:mb-14">
            <h2>Employees</h2>

            <div className="flex flex-wrap -mx-8 mt-6 md:mt-8 md:flex-row flex-col">
              {employees.edges.map((employee, index) => (
                <div key={index} className="w-full md:w-1/2 px-8 mb-8 md:mb-12 flex flex-col md:flex-row md:items-center">
                  <Thumbnail className="thumb overflow-hidden rounded-full flex-none h-14 w-14 md:h-24 md:w-24 mr-4 mb-4 md:mb-0 bg-black shadow-inner" photo={employee.node.photo} />
                  <div className="flex-auto max-w-full overflow-hidden">
                    <h5 className="text-white">{`${employee.node.firstName} ${employee.node.lastName}`}</h5>
                    <a className="block truncate max-w-full" href={`mailto:${employee.node.email}`}>{employee.node.email}</a>
                    <OfficeName office={employee.node.office} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        }

      </div>
    </Layout>
  )
}

const OfficeName = ({office}) => {
  if( office ) {
    return <p>{office.title}</p>
  } else {
    return ''
  }
}

const Thumbnail = ({photo, className}) => {
  return(
    <div className={className}>
      { photo && 
        <img className="h-14 w-14 md:h-24 md:w-24 object-cover" src={photo.resize.src} alt="" />
      }  
    </div>
  )
}



export const query = graphql`
  query ContactPageQuery {
    contentfulStaticPage(slug: {eq: "contact"}) {
      seo {
        ... on Node {
            ... on ContentfulSeo {
            article
            seoTitle
            description
            image {
              file {
                url
              }
            }
          }
        }
      }
      title
      bodyBgColor
      bodyTextColor
      bodyTitleColor
    }
    allContentfulContactDetail(sort: {fields: createdAt, order: ASC}) {
      edges {
        node {
          title
          textLine
          emailLine
        }
      }
    }
    allContentfulOffice {
      edges {
        node {
          title
          addressLine {
            childMarkdownRemark {
              html
            }
          }
          googleMapsUrl
        }
      }
    }
    allContentfulEmployee(sort: {fields: firstName, order: ASC}) {
      edges {
        node {
          firstName
          lastName
          email
          office {
            title
          }
          photo {
            resize(height: 150, width: 150, quality: 90, resizingBehavior: THUMB) {
              src
            }
          }
        }
      }
    }
  }
`
